<template>
  <div class="appointment-view">
    <app-header icon="user-cog" title="Administrador" goes-back></app-header>

    <section>
      <div class="card">
        <div class="card-content">
          <div class="admin">
            <form v-if="!isLoading" @submit.prevent="onSubmit()">
              <div class="row">
                <div class="col-sm-12 col-lg-6 col-xl-4">
                  <app-input
                    name="name"
                    label="Nome"
                    required
                    v-model="admin.name"
                    :errors="errors.name"
                  />
                </div>
                <div class="col-sm-12 col-lg-6 col-xl-4">
                  <app-input
                    name="email"
                    label="Email"
                    v-model="admin.user.email"
                    :errors="errors.email"
                    required
                  />
                </div>
                <div class="col-sm-12 col-lg-6 col-xl-4">
                  <app-input
                    v-model="admin.user.password"
                    :errors="errors.password"
                    type="password"
                    required
                    minlength="6"
                    autocomplete="new-password"
                    name="password"
                    label="Senha"
                  ></app-input>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-12">
                  <b-button type="is-primary" native-type="submit"
                    >Salvar
                  </b-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import AdminsService from '@/services/admins.service.js';

export default {
  data: () => ({
    errors: {},
    isLoading: true,
    title: '',
    admin: { user: {} },
    admins: [],
    email: null,
    password: null,
  }),

  methods: {
    loadAdmin(id) {
      this.isLoading = true;
      if (typeof id != 'undefined') {
        AdminsService.getId(id)
          .then(({ data }) => (this.admin = data))
          .finally(() => (this.isLoading = false));
      }
      this.isLoading = false;
    },
    onSubmit() {
      this.errors = {};
      if (!this.id) {
        this.save({ ...this.admin.user, name: this.admin.name });
      } else {
        this.update(this.id, {
          name: this.admin.name,
          email: this.admin.user.email,
          password: this.admin.password,
        });
      }
    },
    save(data) {
      this.isSaving = true;
      AdminsService.save(data)
        .then(() => {
          this.$buefy.snackbar.open('Administrador cadastrado com sucesso!');
          this.$router.push({ name: 'admins.index' });
        })
        .catch(({ response }) => {
          const { data } = response;

          this.$buefy.snackbar.open(
            'Erro ao tentar salvar o Administrador.',
            'error'
          );

          this.errors = data.errors;
        })
        .finally(() => (this.isSaving = false));
    },
    update(id, data) {
      this.isSaving = true;
      AdminsService.update(id, data)
        .then(() => {
          this.$buefy.snackbar.open('Administrador atualizado com sucesso!');
          this.$router.push({ name: 'admins.index' });
        })
        .catch(({ response }) => {
          const { data } = response;

          this.$buefy.snackbar.open(
            'Erro ao tentar atualizar o Administrador.',
            'error'
          );

          this.errors = data.errors;
        })
        .finally(() => (this.isSaving = false));
    },
  },

  mounted() {
    const id = this.$route.params.id;
    this.id = this.$route.params.id;
    this.loadAdmin(id);
  },
};
</script>

<style lang="scss" scoped>
.patient {
  display: flex;

  &__image {
    margin-right: 24px;
    border: 1px solid #f5f5f5;
    border-radius: 50%;
    overflow: hidden;
    height: 120px;
    width: 120px;

    img {
      object-fit: cover;
    }
  }

  &__info {
    h2 {
      font-size: 1.4rem;
    }

    .links {
      margin-top: 8px;

      .button:not(:first-child) {
        margin-left: 8px;
      }
    }
  }
}
</style>
