import Api from './api.service';

export default {
  get: (term = '') =>
    Api.get(`admins?page=${term.page}&filter[name_or_email]=${term.term}`),
  getId: (id) => Api.get(`admins/${id}`),
  save: (data) => Api.post(`admins/`, data),
  update: (id, data) => Api.put(`admins/${id}`, data),
  delete: (id) => Api.delete(`admins/${id}`),
};
